module.exports = () => {
  /**
   * # Stickyナビ
   */
  function stickyNav () {
    var $stickyNav = $('.nav-desktop-sticky');
    var $staticNav = $('.nav-desktop');

    $(window).scroll(function () {
      if ($(window).scrollTop() >= $staticNav.height()) {
        $stickyNav.addClass('fixed-top d-xl-block');
      } else {
        $stickyNav.removeClass('fixed-top d-xl-block');
      }
    });
  }

  /**
   * # スムーズスクロール
   */
  function smoothScroll () {
    // #main-concept
    // #main-service
    // #main-case-study
    // #main-access
    // #main-news-and-seminar
    //先頭に#がついたリンクがクリックされたら
    $('a[href^="#"]:not(a.noscroll)').click(function () {
      //スクロール速度 単位ミリ秒
      var speed = 300;
      // アンカーの値取得
      var href = $(this).attr('href');
      var target = $(href === '#' || href === '' ? 'html' : href);
      // 移動先を取得
      var position = target.offset().top - 100;
      $('html, body').animate({scrollTop: position}, speed, 'swing');
      return false;
    });
  }

  /**
   * # 要素が画面に現れたらクラスを追加して、アニメーションさせる関数 Animate.css仕様
   */
  function addClassViewElemOnWindow () {
    $(window).scroll(function () {
      $('.set-anim').not('.not-scroll-anim').each(function () {
        var flag = isScrolledIntoView($(this));
        if (flag === 'firstView') {
          // $(this).css({visibility: 'visible'}).removeClass($(this).attr('data-set-anim') + ' animated');
          // *ファーストビューなら何もしない
        } else if (flag === true) {
          $(this).css({visibility: 'visible'}).addClass($(this).attr('data-set-anim') + ' animated');
        } else {
          $(this).css({visibility: 'hidden'}).removeClass($(this).attr('data-set-anim') + ' animated');
        }
      });
    });

    function isScrolledIntoView ($elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();
      var elemTop = $elem.offset().top;
      var elemBottom = elemTop + $elem.height();

      // ファーストインプレッション動作禁止
      if (docViewTop <= $(window).height()) {
        return 'firstView';
      }
      if ((elemTop <= docViewTop) || ((elemBottom <= docViewBottom) && (elemTop >= docViewTop))) {
        return true;
      }
    }
  }

  /**
   * # 記事カテゴリ装飾 バッジ
   */
  function decoCategoryBadge () {
    const $cat = $('.post-cat a');
    $cat.addClass('badge badge-secondary');
  }

  /**
   * # 記事タグ装飾 バッジ
   */
  function decoTagBadge () {
    const $tags = $('.post-tags a');
    $tags.addClass('badge badge-info');
  }

  /**
   * # ブログサイドバーのリスト装飾 ボタン
   */
  function decoSidebarListBtn () {
    const $lists = $('.sidebar-list a');
    $lists.addClass('py-2 d-inline-block');
  }

  function init () {
    jQuery(function () {
      stickyNav();
      smoothScroll();
      addClassViewElemOnWindow();
      decoCategoryBadge();
      decoTagBadge();
      decoSidebarListBtn();
    });
  }

  init();
};

/* Demo purposes only */
$(".hover").mouseleave(
    function () {
      $(this).removeClass("hover");
    }
);
